<template>
  <div>
    <div class="btnzj">
      <el-button type="primary" @click="dialogVisible = true">添加</el-button>
    </div>
    <div class="exporttable">
      <el-table :data="tableData" stripe style="width: 100%">
        <el-table-column label="序号" type="index"> </el-table-column>
        <el-table-column align="center" label="专家姓名" prop="name">
        </el-table-column>
        <el-table-column align="center" label="账号" prop="username">
        </el-table-column>
        <el-table-column align="center" label="单位" prop="company"></el-table-column>
        <el-table-column align="center" label="专业" prop="domain">
        </el-table-column>
        <el-table-column align="center" label="职称" prop="professional_title">
        </el-table-column>
        <el-table-column align="center" label="联系电话" prop="telephone">
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="{row}">
            <span style="color: #00ca43" @click="resetPassword(row)">重置密码</span>
          </template>

        </el-table-column>
      </el-table>
    </div>
        <!-- 分页 -->
    <div class="pageTotal">
      <div class="block">
        <el-pagination :current-page="page" :page-count="totalPage" :page-size="limit" :total="totalCount" background
          layout=" prev, pager, next, total, jumper" @size-change="handleSizeChange"
          @current-change="handleCurrentChange">
        </el-pagination>
      </div>
    </div>
    <!-- 弹窗 -->
    <el-dialog title="专家新增" :visible.sync="dialogVisible" width="30%">
      <div class="zjtable">
        <el-form ref="form" :model="form" label-width="100px">
          <el-form-item label="专家姓名：">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="单位：">
            <el-input v-model="form.company"></el-input>
          </el-form-item>
          <el-form-item label="专业：">
            <el-input v-model="form.domain"></el-input>
          </el-form-item>
          <el-form-item label="职称：">
            <el-input v-model="form.professional_title"></el-input>
          </el-form-item>
          <el-form-item label="联系电话：">
            <el-input v-model="form.telephone"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addP">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import pinyin from '../../../../../node_modules/js-pinyin/index'
import { pStoreApi, resetApi, zplistApi } from "../../../../api/project";

export default {
  data() {
    return {
      dialogVisible: false,
      tableData: [],
      form: {},
      page: 1,
      limit: 13,
    };
  },
  mounted() {
    this.getPList();
    console.log(pinyin.getFullChars('管理员')) //GuanLiYuan;
  },
  methods: {

    //重置密码
    resetPassword(row) {
      let params = {
        token: this.$store.state.token,
        id: row.id,
      };
      let that = this;
      resetApi(params).then(res => {
        console.log(res);
        that.$message.success('重置成功');
      })
    },

    //获得专家列表
    getPList() {
      let params = {
        token: this.$store.state.token,
        limit: this.limit,
        page: this.page,
      }
      let that = this;
      zplistApi(params).then(res => {
        console.log(res);
        that.tableData = res.data;
          that.page = res.page;
        that.totalCount = res.totalCount;
        that.totalPage = res.totalPage;
      })
    },


    //添加专家
    addP() {
      let params = {
        token: this.$store.state.token,
      }
      params = Object.assign(params, this.form);
      let username = pinyin.getFullChars(this.form.name);
      params.username = username.toLowerCase();
      let that = this;
      pStoreApi(params).then(res => {
        console.log(res);
        that.$message.success('添加成功');
        that.dialogVisible = false;
        that.getPList();
      })
    },
   // 分页
    handleSizeChange(page) {
      this.limit = page;
      this.getPList();
      //console.log(`每页 ${page} 条`)
    },
    handleCurrentChange(page) {
      //console.log(`当前页: ${page}`)
      this.page = page;
      this.getPList();
    },

  }
};
</script>
<style scoped>
/deep/.el-dialog__headerbtn .el-dialog__close {
  color: #ffffff;
}

/deep/.el-dialog__title {
  color: #ffffff;
}

/deep/.el-dialog {
  border-radius: 10px;
}

/deep/.el-dialog__header {
  background-color: #3086fb;
  font-weight: bold;
  border-radius: 5px 5px 0 0;
}

.btnzj {
  display: flex;
  flex-direction: row-reverse;
  margin: 20px 0;
}

.pageTotal {
  margin-top: 37px;
  display: flex;
  justify-content: center;
}
.exporttable{
height: 70vh;
}
</style>
